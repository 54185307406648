import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import headerStyles from './mods/header.module.css'


const Header = ({ siteTitle }) => (
  <header className="h-2 w-full">
  </header>
)


export default Header
